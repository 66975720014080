import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link", "image"]

  updateDom (e) {
    this.resetActiveClasse()
    console.log(this.imageTargets)

    this.imageTargets.forEach((image) => {
      if (image.dataset.id === e.target.dataset.id) {
        image.classList.add('active')
      }
    })
  }

  resetActiveClasse () {
    this.imageTargets.forEach((image) => {
      image.classList.remove('active')
    })
  }
}
