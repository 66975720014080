import { Controller } from "@hotwired/stimulus"

import Macy from 'macy'

export default class extends Controller {
  static values = {
    options: Object
  }

  connect () {
    // Hang a reference to the Stimulus controller instance off the DOM element
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this

    this.macy = new Macy({
      ...this.defaultOptions,
      ...this.optionsValue
    })
  }

  disconnect () {
    this.macy.remove()
    this.macy = undefined
  }

  get defaultOptions () {
    return {
      container: this.element,
      waitForImages: false,
      useOwnImageLoader: false,
      debug: true,
      mobileFirst: true,
      columns: 1,
      margin: {
          y: 20,
          x: 20
      },
      breakAt: {
        1200: 3,
        520: 2
      }
    }
  }
}
