import { Controller } from "@hotwired/stimulus"
import { gsap, Power4, CSSPlugin } from "gsap/all"

gsap.registerPlugin(CSSPlugin)

export default class extends Controller {
  static targets = ["content", "link"]
  static values = {
    maxHeight: Number,
    textOpen: String,
    textClose: String
  }

  connect () {
    this.maxHeight = this.maxHeightValue || '120'
    this.textOpen = this.textOpenValue || 'En savoir plus'
    this.textClose = this.textCloseValue || 'Réduire'

    this.isOpen = false
    this.element.classList.toggle("active", this.isOpen)

    window.addEventListener('load', () => {
      this.closeAnimation()
    }, false)
  }

  handleClickLink () {
    !this.isOpen ? this.openAnimation() : this.closeAnimation()
    this.isOpen = !this.isOpen
    this.element.classList.toggle("active", this.isOpen)
  }

  closeAnimation () {
    gsap.to(this.contentTarget, { height: this.maxHeight, overflow: 'hidden', duration: 0.5 })
    this.linkTarget.innerHTML = this.textOpen
  }

  openAnimation () {
    gsap.to(this.contentTarget, { height: 'auto', ease: Power4.easeOut, duration: 0.5 })
    this.linkTarget.innerHTML = this.textClose
  }
}
